export const IS_REQUIRED = "is required";

export const BASIC_PLAN = "basic";
export const PREMIUM_PLAN = "premium";
export const ENTERPRISE_PLAN = "enterprise";
export const FREE_PLAN = "free";

export const ANNUALLY = "annually";
export const MONTHLY = "monthly";
export const ANNUAL = "annual";

export const PUBLIC_STRIPE_KEY_TEST =
  "pk_test_51PjPmIFIUV8MwuoSwbbaah5w7xO178ADkRFMRYYINtjne27DnqJTq4iOzwhjFngwoLRAMJ5249s3kAvSjQaOdFYJ007to5zNzF";
export const PUBLIC_STRIPE_KEY_LIVE =
  "pk_live_51PjPmIFIUV8MwuoSWUCtDnB5ZhwhLgZ0G2qTW98u1nGXk8UIS8vrQSjvP0fMc4cgaN8K1JnQXrRPczYN3nZ4tpzf00kySvTZxe";
export const BASIC_MONTHLY = "price_1Pnoi4FIUV8MwuoSIvpq0QMc";
export const BASIC_ANNUALLY = "price_1PnojHFIUV8MwuoS7mbxiJV1";
export const PREMIUM_MONTHLY = "price_1PpYtfFIUV8MwuoSgfs8xGvn";
export const PREMIUM_ANNUALLY = "price_1PpYvCFIUV8MwuoS2va2ulpn";
export const ENTERPRISE_MONTHLY = "price_1PpYx6FIUV8MwuoSFnRuUmST";
export const ENTERPRISE_ANNUALLY = "price_1PpYxXFIUV8MwuoSeSLIFD6z";

export const PREMIUM_MONTHLY_PROD = "price_1PjRZjFIUV8MwuoSVo2jr9ET";
export const PREMIUM_ANNUALLY_PROD = "price_1PjRZjFIUV8MwuoSP1mys8sX";

export const USE_ACTUAL_REPORTED_TAXES = false;
export const REPORTED_TAX_PERCENT = 0.3;
export const OFFER_PRICE_PERCENT = 1.0;

export const MLO_LENDER_ROLE = "MLO-Lender";

export const COMPANY_SETUP_STEPPER_LABELS = [
  "Company",
  "MLO Settings",
  "Borrower Settings",
  "Offer/Qualification Letter",
  "Property Tax Rules",
];

export const UPLOAD_LOAN_STEPPER_LABELS = ["Upload Documents", "Match Fields"];

export const COMPANY_INFO_CONTACT_INPUT_NAMES = [
  "companyName",
  "phoneNumber",
  "nmls",
  "webURL",
];

export const COMPANY_INFO_CONTACT_INPUT_LABELS = [
  "Company Name",
  "Phone Number",
  "NMLS",
  "Website",
];

export const COMPANY_INFO_ADDRESS_INPUT_NAMES = [
  "street1",
  "street2",
  "city",
  "state",
  "zipCode",
];

export const COMPANY_INFO_ADDRESS_INPUT_LABELS = [
  "Street Address 1",
  "Street Address 2",
  "City",
  "State",
  "ZIP",
];

export const PHONE_MASK = [
  "(",
  /\d/,
  /\d/,
  /\d/,
  ")",
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const COMPANY_FIELDS_VALIDATIONS = {
  companyName: {
    error: "",
    validate: "",
    minLength: 2,
    maxLength: 150,
  },
  street1: {
    error: "",
    validate: "",
    minLength: 0,
    maxLength: 150,
  },
  street2: {
    error: "",
    validate: "",
    minLength: 0,
    maxLength: 150,
  },
  city: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 150,
  },
  state: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 2,
  },
  zipCode: {
    error: "",
    validate: "zip",
    minLength: 0,
    maxLength: 5,
  },
  phoneNumber: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
  nmls: {
    error: "",
    validate: "number",
    minLength: 0,
    maxLength: 12,
  },
  webURL: {
    error: "",
    validate: "website",
    minLength: 0,
    maxLength: 150,
  },
};

export const MLO_PROFILE_VALIDATIONS = {
  firstName: {
    error: "",
    validate: "text",
    minLength: 2,
    maxLength: 150,
  },
  lastName: {
    error: "",
    validate: "text",
    minLength: 2,
    maxLength: 150,
  },
  businessEmail: {
    error: "",
    validate: "email",
    minLength: 0,
    maxLength: 150,
  },
  jobTitle: {
    error: "",
    validate: "",
    minLength: 0,
    maxLength: 150,
  },
  nmls: {
    error: "",
    validate: "number",
    minLength: 0,
    maxLength: 15,
  },
  mobilePhone: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
  officePhone: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
};

export const LOGIN_VALIDATIONS = {
  email: {
    error: "",
    validate: "email",
    minLength: 0,
    maxLength: 150,
  },
  password: {
    error: "",
    validate: "",
    minLength: 8,
    maxLength: 20,
  },
};

export const CHANGE_PASSWORD_VALIDATIONS = {
  newPassword: {
    error: "",
    validate: "password",
    minLength: 8,
    maxLength: 20,
  },
  confirmPassword: {
    error: "",
    validate: "password",
    minLength: 8,
    maxLength: 20,
  },
};

export const SIGN_UP_VALIDATIONS = {
  firstName: {
    error: "",
    validate: "text",
    minLength: 2,
    maxLength: 150,
  },
  lastName: {
    error: "",
    validate: "text",
    minLength: 2,
    maxLength: 150,
  },
  email: {
    error: "",
    validate: "email",
    minLength: 8,
    maxLength: 150,
  },
  password: {
    error: "",
    validate: "password",
    minLength: 8,
    maxLength: 20,
  },
  confirmPassword: {
    error: "",
    validate: "password",
    minLength: 8,
    maxLength: 20,
  },
};

export const LOAN_VALIDATIONS = {
  borrowerFirstName: {
    error: "",
    validate: "text",
    minLength: 1,
    maxLength: 150,
  },
  borrowerLastName: {
    error: "",
    validate: "text",
    minLength: 1,
    maxLength: 150,
  },
  borrowerEmail: {
    error: "",
    validate: "email",
    minLength: 1,
    maxLength: 150,
  },
  borrowerMobilePhone: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
  city: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 150,
  },
  state: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 2,
  },
  zipCode: {
    error: "",
    validate: "number",
    minLength: 0,
    maxLength: 5,
  },
  coBorrowerFirstName: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 150,
  },
  coBorrowerLastName: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 150,
  },
  realtorFirstName: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 150,
  },
  realtorLastName: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 150,
  },
  realtorEmail: {
    error: "",
    validate: "email",
    minLength: 0,
    maxLength: 150,
  },
  realtorMobilePhone: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
  totalCashInBank: {
    error: "",
    validate: "biggerThanZero",
  },
  noteRate: {
    error: "",
    validate: "biggerThanZero",
  },
  loanAmount: {
    error: "",
    validate: "biggerThanZero",
  },
  prepaidInterestDays: {
    error: "",
    validate: "biggerThanZero",
  },
  oneTimePMI: {
    error: "",
    validate: "biggerThanZero",
  },
  lenderTitleIns: {
    error: "",
    validate: "biggerThanZero",
  },
  term: {
    error: "",
    validate: "biggerThan120",
  },
  totalHousingExpenses: {
    error: "",
    validate: "biggerThanZero",
  },
  purchasePrice: {
    error: "",
    validate: "biggerThanZero",
  },
  terms: {
    error: "",
    validate: "biggerThan120",
  },
};

export const LOAN_EDITOR_MODES = {
  CREATE_LOAN: "CREATE-NEW",
  ADD_LOAN: "ADD-NEW",
  EDIT_LOAN: "EDIT-LOAN",
};

export const LOAN_DEFAULT_INITIAL_VALUES = {
  noteRate: 0.0,
  loanAmount: 0.0,
  originationFeePerc: 0.0,
  originationFeeAmt: 0.0,
  brokerFeePerc: 0.0,
  brokerFeeAmt: 0.0,
  lenderCreditPerc: 0.0,
  lenderCreditAmt: 0.0,
  discountPointsPerc: 0.0,
  discountPointsAmt: 0.0,
  prepaidInterestDays: 0.0,
  oneTimePMI: 0.0,
  mipOrVAFee: 0.0,
  mtgInsReserveMths: 0.0,
  homeownerInsMo: 0.0,
  hoiReserveMths: 0.0,
  propTaxReserveMths: 0.0,
  floodInsMo: 0.0,
  floodInsReserveMths: 0,
  otherInsMo: 0.0,
  otherInsReserveMths: 0,
  lenderTitleIns: 0.0,
  mtgstamps: 0.0,
  deedstamps: 0.0,
  totalAMTPaidToOthers: 0.0,
  term: 0,
  mgtInsMonthly: 0.0,
  totalHousingExpenses: 0.0,
  purchasePrice: 0.0,
  otherCreditsAmt: 0.0,
  otherCreditsPerc: 0.0,
  loanType: "",
  ltv: 0.0,
  downPaymentPerc: 0.0,
  insMtgInsPeriodFactor1: 0.0,
  allNonVariableFees: 0.0,
  borrowerFirstName: "",
  borrowerLastName: "",
  borrowerFullName: "",
  borrowerEmail: "",
  borrowerMobilePhone: "",
  borrowerId: "",
  borrowerMthIncome: 0.0,
  coBorrowerFirstName: "",
  coBorrowerLastName: "",
  coBorrowerFullName: "",
  coBorrowerMthIncome: 0.0,
  addtlCoBorrowerIncome: 0.0,
  totalCashInBank: 0.0,
  realtorFirstName: "",
  realtorLastName: "",
  realtorFullName: "",
  realtorEmail: "",
  realtorMobilePhone: "",
  realtorId: "",
  vaFirstUse: true,
  prepaidFinanceCharges: 0.0,
  propTaxMo: 0.0,
  streetAddress: "",
  unitNo: "",
  city: "",
  state: "",
  zipCode: "",
};

export const ERROR_LOADING_USER_PROFILE =
  "We're having trouble loading your profile information at the moment. Please try refreshing the page or come back later. If the problem persists, contact our support team for assistance.";
export const ERROR_LOADING_COMPANY_DATA =
  "We're having trouble loading your company information at the moment. Please try refreshing the page or come back later. If the problem persists, contact our support team for assistance.";
export const ERROR_LOADING_BUYERS =
  "We're having trouble loading your buyers loans information at the moment. Please try refreshing the page or come back later. If the problem persists, contact our support team for assistance.";
export const ERROR_LOADING_PENDING_BUYERS =
  "We're having trouble loading your buyers invitations information at the moment. Please try refreshing the page or come back later. If the problem persists, contact our support team for assistance.";

export const FORGOT_PASSWORD_MESSAGE =
  "We will send you an One Time Password (OTP) code to your email. Please check your spam or junk folder if it's not in your inbox. This can take some time. Please do not retry again to avoid further days.";
